/**
Savoir si c'est un nombre sans unité
function is-number
@return {Boolean}
 */
@function is-number($value){
    @return type-of($value) == 'number' and unit($value) == '';
}

/**
Savoir si c'est un nombre en pourcentage
function is-percentage
@return {Boolean}
 */
@function is-percentage($value){
    @return unit($value) == '%';
}

/**
Template 1 produits page accueil : taille des produits

/!\ En utilisant cette fonction il faudra sans doute devoir modifier le fichier JS suivant :  /!\
js/design/templates/accueil_boutique/templates.js => dans le switch de la fonction getParamsSerie

@param {Number} $largeurFleches - valeur en pixel pour caler les produits en fonction de la largeur des flèches (si elles sont sensées passer au-dessus, mettre à 0)
@param {Number} $nbrProduit - Nombre de produits à afficher dans le slider et au chargement de celui-ci
@param {Number} $margin - valeur en pixel de la marge droite de chaque produit (dans le style pas mettre de marge à gauche)
 */
@mixin produitAccueilTaille($largeurFleches, $nbrProduit, $margin){
    $nbrProduitPlus:$nbrProduit+1;
    .produits{
        width:calc(((100% - #{$largeurFleches}) - ((#{$nbrProduit} - 1) * #{$margin})) / #{$nbrProduit});
        margin-right:$margin;
        margin-left:0;
        &:nth-child(#{$nbrProduit}){
            margin-right:0;
        }
        &:nth-child(-n+#{$nbrProduit}){
            visibility: visible;
            position: relative;
        }
        &:nth-child(n+#{$nbrProduitPlus}){
            visibility: hidden;
            position: absolute;
        }
    }
    .bx-wrapper .produits{
        &:nth-child(n+#{$nbrProduitPlus}){
            visibility: visible;
            position: relative;
        }
        &:nth-child(#{$nbrProduit}){
            margin-right:$margin;
        }
    }
}

/**
Taille des produits des listes de produits

/!\ En utilisant cette fonction il faudra sans doute devoir modifier le fichier JS suivant :  /!\
js/design/templates/boutique/templates.js => dans le switch haut de page

@param {Number} $nbrProduit - Nombre de produits sur la ligne
@param {Number} $marge - les marges entre chaque produit sur les côtés (va être en %)
@param {Boolean} $float - Indique si les produits sont des floattants ou pas
 */
@mixin elemTailleCol($nbrEl:3, $marge:1.25, $float:false){
    $marginTot: $marge*$nbrEl*2 - $marge*2;
    $is-number: is-number($marge);
    $is-percentage: is-percentage($marge);

    @if ($is-number) or ($is-percentage){
        width: ((100%/$nbrEl) - ($marge * 2));
        @if $is-percentage{
                margin-left: #{$marge};
                margin-right: #{$marge};
        } @else {
                margin-left: #{$marge}#{'%'};
                margin-right: #{$marge}#{'%'};
        }
    } @else {
        width: calc((100% / #{$nbrEl}) - (#{$marginTot} / #{$nbrEl}));
            margin-left: #{$marge};
            margin-right: #{$marge};
    }



}

/**
Incorpore les tailles de base des navigateurs + leur équivalent avec les classes
 */
@mixin titreBaseSize(){
    @for $i from 1 through 6{
        h#{$i}, .h#{$i}{
            display: block;
            font-weight: bold;
            $calc : 2.266 - $i*0.266;
            font-size: #{$calc}rem;
            @if $i == 1 {
                margin-top:21px;
                margin-bottom:21px;
            } @else if $i == 2 {
                margin-top:19px;
                margin-bottom:19px;
            } @else if $i == 3 {
                margin-top:18px;
                margin-bottom:18px;
            } @else if $i == 4 {
                margin-top:21px;
                margin-bottom:21px;
            } @else if $i == 5 {
                margin-top:22px;
                margin-bottom:22px;
            } @else {
                margin-top:25px;
                margin-bottom:25px;
            }
        }
    }
}

/********************************
    TEMPLATE FLUX PANIER
 *******************************/

@mixin colorFluxPanier($color_shadow:black, $fullWidth:false){
    @if $fullWidth {
        #resume, .recapitulatif.recap-l{
            background: rgba($color_shadow,0.02);
        }
    }
}