.accueil_boutique_template_2 {
    .produits{
        @include elemTailleCol(3 , 1.5, true);
        float:left;
        position:relative;
        margin-bottom: 15px;
        .desc{
            display: none;
            box-sizing: border-box;
        }
        &:hover{
            .zoom{
                opacity: .5;
                &::before{
                    color: #fff;
                }
            }
        }
    }
    .productImageWrap{
        position:relative;
    }
    .nomprod{
        display:block;
        width:100%;
        box-sizing: border-box;
        a{
            display: inline-block;
            width: 100%;
            line-height: normal;
            vertical-align: middle;
        }
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
    }
    .lien-mask{
        opacity:0;
    }
    .prix{
        text-align:center;
        margin:15px auto 0;
        .prixprod{
            margin-right:0;
        }
        .oldprix{
            display:inline-block;
            margin-left:10px;
        }
    }
    .desc{
        padding:10px;
    }
    .remise{
        left:0;
        right:auto;
    }
    .block-btn-addbasket{
        margin: 0 20px;
    }
    .addbasket {
        float: none;
        height:auto;
        position:relative;
        margin:10px auto 10px;
        display:block;
        clear:both;
        top:0;
        box-sizing: border-box;
        text-align: center;
    }
    .hors-stock{
        margin-bottom:10px;
        height:32px;
        clear:both;
        float:none;
        margin-top:10px;
        font-size: 0;
        display: block;
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
        .inside-hors-stock{
            display: inline-block;
            font-size: .7rem;
            vertical-align: middle;
        }
    }
    .fa-shopping-cart {
        margin-right: 5px;
    }
    .desc{
        position:relative;
        display:block;
        top:0;
    }
    .mask{
        z-index:2;
        height:100%;
        width:100%;
        top:0;
        left:0;
        margin:0;
        padding:0;
        border: 0;
    }
    .zoom{
        top:0;
        left: 0;
        position:absolute;
        width:100%;
        height:100%;
        opacity:0;
        z-index: 2;
        padding:0;
        margin: 0;
        border:0;
    }
    .container-etiquette{
        z-index: 3;
        position:relative;
    }
    .zoom::before{
        content: "\f002";
        font-family: fontawesome, sans-serif;
        font-size: 80px;
        line-height: 80px;
        top: 50%;
        left: 0;
        right: 0;
        position: absolute;
        margin: -40px auto 0;
        display: block;
        text-align: center;
    }
    &::before,&::after{
        content:'';
        display:block;
        clear:both;
    }
}

@media #{$medium-and-down}{
    .accueil_boutique_template_2 .produits{
        @include elemTailleCol(2 , 1.5, true);
    }
}

@media #{$small-and-down}{
    .accueil_boutique_template_2 {
        .produits{
            @include elemTailleCol(1 , 1.5, true);
            width:100%;
            margin-top:20px;
            margin-bottom:20px;
            float:none;
            padding-bottom:15px;
        }
        .hors-stock{
            float:none;
        }
        .mask{
            display:none;
        }
    }
}