$largeurFleches: 70px;
$margin: 20px;
$nbrProduit: 4;

.accueil_boutique_template_1 {
    width:100%;
    margin:30px auto 50px;
    @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
    &.is_etiquette{
        .remise{
            left:0;
        }
    }
    .list-produits{
        display:block;
        text-align: center;
        font-size: 0;
        &::after{
            content:'';
            display:block;
            width:100%;
            clear:both;
        }
    }
    .remise{
        left:0;
    }
    .fa-shopping-cart{
        margin-right:5px;
    }
    .bx-wrapper {
        margin: auto;
        .bx-pager{
            padding-top:5px;
            a{
                color:transparent;
                &:hover{
                    color:transparent;
                }
            }
        }
        .bx-viewport{
            margin-left:0;
        }
    }
    .bx-controls-direction a{
        position: absolute;
        top: 45%;
        width: 32px;
        height: 32px;
        text-indent: -9999px;
        z-index: 100;
        transition-property: all;
        margin-top: 10px;
        transition-timing-function: ease-in-out;
        &.bx-prev {
            left: -35px;
            background: url(/images/controls.png) no-repeat 0 -32px;
        }
        &.bx-next {
            right: -35px;
            background: url(/images/controls.png) no-repeat -43px -32px;
        }
    }
    .productImageWrap {
        margin-top: 0;
        position:relative;
        width:100%;
        img{
            width:100%;
        }
    }
    .container-etiquette{
        position:relative;
        z-index:21;
    }
    .produits {
        font-size: 1rem;
        left:0;
        text-align: center;
        float: none;
        display: inline-block;
        margin: 0 $margin 10px 0;
        box-sizing: border-box;
        position: relative;
        padding: 0 0 10px;
        max-width:100%;
        vertical-align: top;
        &:hover{
            .mask {
                .zoom{
                    opacity: .5;
                }
            }
        }
    }
    .lien-mask{
        opacity:0;
    }
    .mask {
        top: 0;
        height:100%;
        z-index:1;
    }
    .zoom{
        width:100%;
        height:100%;
        opacity:0;
        transition:all .2s linear;
        position:absolute;
        display:block;
        top:0;
        left:0;
        z-index:1;
        border-color:transparent;
        padding:0;
        margin:0;
        &::before{
            content: "\f002";
            font-family: fontawesome, sans-serif;
            font-size:60px;
            top:50%;
            left:0;
            right:0;
            position:absolute;
            margin:-30px auto 0;
            display: block;
        }
    }
    .prix {
        text-align: center;
    }
    select {
        display: block;
        position: relative;
        top: initial;
        left: initial;
        width: auto;
        max-width:100%;
        margin:0 auto;
        float:none;
        clear:both;
    }

    .block-btn-addbasket{
        margin: 0 20px;
    }

    .addbasket {
        float: none;
        height:auto;
        position:relative;
        margin:0 auto 10px;
        display: inline-block;
        padding:10px;
    }
    .hors-stock{
        margin-bottom:10px;
        height:32px;
        margin-top:0;
        font-size: 0;
        display: block;
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
        .inside-hors-stock{
            display: inline-block;
            font-size: .7rem;
            vertical-align: middle;
        }
    }
    .attr{
        margin-bottom:10px;
    }
    .nomprod{
        display:block;
        width:100%;
        a{
            display: inline-block;
            width: 100%;
            vertical-align: middle;
            line-height: initial;
        }
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
    }
    .desc {
        display: none;
    }
    a.bx-prev, a.bx-next, .bx-pager-item a{
        &, &:hover, &:focus{
            outline: none;
        }
    }
}

@media #{$medium-and-down}{
    $largeurFleches: 70px;
    $margin: 20px;
    $nbrProduit: 2;
    .accueil_boutique_template_1 {
        @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
    }
}

@media #{$small-and-down}{
    $largeurFleches: 70px;
    $margin: 0px;
    $nbrProduit: 1;
    .accueil_boutique_template_1 {
        @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
        .mask{
            display:none;
        }
    }
}